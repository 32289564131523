import * as React from "react"

import Logo from "../images/logo.svg"
import BlueCircle from "../images/blue-circle.svg"
import CircleWithEllipse from "../images/circle-with-ellipse.svg"
import XRay from "../images/xray.svg"
import Siemens from "../images/siemens.svg"
import Overlay from "../images/overlay.svg"
import {Helmet} from "react-helmet";

const IndexPage = () => {
  const name = 'homepage';

  return (
    <div className={'wrapper'}>
      <Helmet
        title="Rayfree"
        meta={[
          {name: "description", content: "Our goal is to combine adjustable effective shielding with real-time monitoring of personnel exposure"},
          {name: "og:title", content: "Rayfree"},
          {name: "og:description ", content: "Our goal is to combine adjustable effective shielding with real-time monitoring of personnel exposure"},
          {name: "og:url ", content: "https://rayfree.pl"},
          {name: "twitter:card ", content: "summary"},
        ]}
        link={[
          {rel: 'icon', href: 'img/fav.ico', type: "image/x-icon"},
          {rel: 'shortcut icon', href: 'img/fav.ico', type: "image/x-icon"}
        ]}
      />
      <main className={name}>
        <section className={name + '__hero'}>
          <div bp={'container'}>
            <div bp={'grid'}>
              <div bp={'4 12@md offset-3@xl 6@xl'}>
                <div className={'img'} data-sal="slide-up" data-sal-easing={'easeOutCirc'}>
                  <img src={Logo} alt="logo of brand" style={{width: '146px'}}/>
                </div>
                <p className={'big-paragraph'} data-sal="slide-up" data-sal-easing={'easeOutCirc'}>
                  Our goal is to combine adjustable effective shielding with real-time monitoring of personnel exposure
                  allowing them to get rid of heavy personal protection aprons and work conveniently in an X-ray free
                  environment.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className={name + '__content'}>
          <div className={'homepage-gradient'} data-sal="slide-up" data-sal-easing={'easeOutCirc'} data-sal-delay="500">
            <img src={Overlay} alt="gradient"/>
          </div>
          <div bp={'container'}>
            <div className={'first-row'} bp={'grid'}>
              <div bp={'4 12@md 5@lg offset-2@xl 4@xl 5@xxl'}>
                <div className={'img'} data-sal="slide-up" data-sal-easing={'easeOutCirc'}>
                  <img src={BlueCircle} alt="blue, filled circle"/>
                </div>
                <p className={'big-paragraph big-paragraph--bold big-paragraph--white'} data-sal="slide-up"
                   data-sal-easing={'easeOutCirc'}>
                  Scattered radiation is harmful and causes many potential health problems, which can be avoided.
                </p>
              </div>
              <div bp={'4 12@md offset-8@lg 5@lg offset-7@xl 5@xl'}>
                <div className={'img'} data-sal="slide-up" data-sal-easing={'easeOutCirc'}>
                  <img src={CircleWithEllipse} alt="blue, filled circle"/>
                </div>
                <p className={'big-paragraph big-paragraph--bold big-paragraph--white'} data-sal="slide-up"
                   data-sal-easing={'easeOutCirc'}>
                  Current systems are mainly based on heavy, inconvenient personal protection aprons leaving large
                  areas of the body uncovered. And table/sealing mounted shields are inefficient for full protection.
                </p>
              </div>
            </div>
            <div className={'second-row'} bp={'grid'}>
              <div bp={'4 12@md offset-3@lg 1@lg offset-5@xl'} className={'second-row__img'} data-sal="slide-up"
                   data-sal-easing={'easeOutCirc'}>
                <div className={'img'}>
                  <img src={XRay} alt="x-ray icon"/>
                </div>
              </div>
              <div bp={'4 12@md offset-5@lg 7@lg offset-6@xl 4@xl'} data-sal="slide-up" data-sal-easing={'easeOutCirc'}>
                <p className={'big-paragraph big-paragraph--bold big-paragraph--white'}>
                  We want to create an X-ray free environment for medical professionals performing any treatment under
                  C-arm guidance.
                </p>
              </div>
            </div>
            <div className={'third-row'} bp={'grid'}>
              <div bp={'4 12@md offset-3@lg 8@lg offset-3@xl 8@xl'}>
                <p className={'big-paragraph big-paragraph--bold big-paragraph--white big-paragraph--center-lg'}
                   data-sal="slide-up" data-sal-easing={'easeOutCirc'}>
                  With our team of radiologist experts performing tons of different procedures under C-arm, we believe
                  that x-ray protection must improve. It's not only about quality but, what's most important, about
                  being 100% adjustable and personalized.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className={'footer-content'} bp={'container'} data-sal="slide-up" data-sal-easing={'easeOutCirc'}>
          <p className={'footer-paragraph'}>
            Rayfree sp. z o.o. <br/>
            ul. Nieporęcka 2/10 <br/>
            VATID: 1133004210 <br/>
            Warsaw, Poland
          </p>
          <div className={'footer-siemens'}>
            Our partners: <br/>
            <div className={'img'}>
              <img src={Siemens} alt="siemens logo"/>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default IndexPage
